import {Link} from "gatsby";
import styled from "styled-components";
import {Secondary} from "../../components/layouts/Secondary/Secondary";

const Wrapper = styled.section`
  padding: 100px 11.32%;
  @media (max-width: 599px) {
    padding: 50px 8.32%;
  }
  main {
    .footer-note {
      border-top: 1px solid #353945;
      font-size: 1.1rem;
      margin-top: 35px;
      padding-top: 10px;
    }
    ul {
      padding: 10px 21px;
      li {
        list-style: disc;
      }
    }
    li {
      list-style: auto;
      margin-top: 15px;
      font-size: 1.25rem;
      line-height: 34px;
      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 27px;
      }
    }
    .list-style-none li {
      list-style: none;
    }
    .padding-none {
      padding: 0;
    }
    .margin-none {
      margin: 0;
    }
    .ul-li-margin-none {
      li {
        margin: 0;
      }
    }
    .line-height-normal {
      line-height: normal;
    }
    ol {
      @media (max-width: 599px) {
        padding-left: 25px;
      }
    }
    ol.list-style-lower-roman li {
      list-style: lower-roman;
    }
    ol.list-style-lower-alpha li {
      list-style: lower-alpha;
    }
    h2 {
      margin-top: 30px;
      font-size: 1.6rem;
      text-align: left;
      @media (max-width: 599px) {
        font-size: 1.3rem;
        line-height: 1.6;
      }
    }
    h4 {
      @media (max-width: 599px) {
        font-size: 18px;
      }
    }
    p {
      margin-top: 10px;
      text-align: left;
    }
    .ol-left-padding {
      padding-left: 90px;
    }
    .underline-text {
      text-decoration: underline;
    }
    .font-style-italic {
      font-style: italic;
    }
  }
`;

const TermsAndConditions = () => (
  <Secondary>
    <Wrapper>
      <main>
        <h2 className="last-updated">Last updated: November 24, 2022</h2>
        <ol>
          <li>
            <h2>Introduction and purpose</h2>
            <p>
              Welcome to Flahmingo Investments Inc. (“Flahmingo“, “we“, or
              “us“).
            </p>
            <p>
              Flahmingo is registered as a restricted dealer in Alberta, subject
              to specific terms and conditions contained in the exemptive relief
              decision Re Flahmingo Investments Inc., dated June 15, 2022, which
              permits Flahmingo to operate its investment platform on which
              Alberta residents can buy, sell and hold US traded stocks on a
              full or fractional basis. As such, Flahmingo may not be subject to
              all of the requirements otherwise applicable to an investment
              dealer and a member of the Investment Industry Regulatory
              Organization of Canada (IIROC), including those that apply to
              marketplaces and to trading on marketplaces, until such time as it
              becomes a member of IIROC. Our principal regulator is the Alberta
              Securities Commission (ASC).
            </p>
            <p>
              Flahmingo offers easy to use portfolio building apps, fractional
              shares, automated functionality, educational resources, and mobile
              access to the Flahmingo app.
            </p>
            <p>
              Please review the information provided in this Account
              Relationship Disclosure (ARD) document. The purpose of this ARD is
              to provide you with a good understanding of the services you will
              receive when you open an account at Flahmingo. It contains
              important details about the services we offer, the features of
              your account and how it operates, and our responsibilities to you.
            </p>
            <p>
              Should there be material changes impacting this ARD, we will let
              you know.
            </p>
            <p>
              If you have any questions or if we can be of assistance in any
              way, please contact us at <Link to="/">www.flahmingo.com.</Link>
            </p>
          </li>
          <li>
            <h2>
              Description of the client accounts and products and services
              offered
            </h2>
            <p className="underline-text font-style-italic">Products</p>
            <p>
              Flahmingo offers permitted account holders access to Tax-Free
              Savings Accounts (TFSA) which can be used to purchase, hold and
              trade certain U.S. equities that are not dually listed on a
              Canadian exchange (e.g. stocks and Exchange-Traded Funds (ETFs)).
            </p>
            <p>
              Generally, the investment products available on our trading
              platform will be able to be readily liquidated or resold.
            </p>
            <p>
              In the event that one or more of the securities held in your TFSA
              are no longer offered (delisted securities), you will not be able
              to trade in the securities and this will be noted on your account
              statement
            </p>
            <p>
              For more information on investment products, you can read the
              investment explanations in the Investments at a Glance, a booklet
              prepared by the Canadian Securities Administrators (CSA) for
              financial consumers. It’s available on the CSA website at{" "}
              <a href="http://www.securities-administrators.ca">
                www.securities-administrators.ca
              </a>
              .
            </p>
            <p className="underline-text font-style-italic">Services</p>
            <p>
              Flahmingo provides order-execution service accounts on U.S.
              equities that are not dually listed on Canadian exchanges. We
              engage the execution services of Alpaca Securities LLC (“Alpaca“),
              a broker/dealer registered in the United States for U.S. listed
              equities and options, for all equity trades (see the description
              below). In executing your trades, Alpaca may receive payment for
              order flows from third-party brokers. This is described in more
              detail in Flahmingo‘s Conflict of Interest Statement.
            </p>
            <p>
              You can enter orders using our online trading platform through the
              Flahmingo app. Using our accounts, you can create one or more
              self-directed portfolios which can be populated with full or
              fractional shares of individual stocks and ETFs. You can also
              trade your selected full or fractional shares of stocks and ETFs
              on a real-time basis. As beneficial owners of full or fractional
              shares of individual stocks and ETFs, you still have the right to
              receive dividends (if issued) and the ability to exercise voting
              rights in proportion to your interest in the fractional shares and
              ETFs. You must first convert currency into U.S. dollars prior to
              commencing trading on the Flahmingo app. Flahmingo may utilize its
              own cash or engage a foreign exchange provider for the purpose of
              currency conversion.
            </p>
            <p>
              Flahmingo utilizes a third-party service provider to facilitate
              voting rights. When you vote your interest in a fractional share,
              the fractional position voted by you is added to the aggregate
              vote total for all shares of such security to the applicable vote
              tabulator for the meeting or shareholder event. Neither Flahmingo
              nor its third party service provider are affiliated with any
              tabulators. Each tabulator may follow differing policies for
              counting fractional share positions submitted to it for a meeting
              or shareholder event, and it is therefore possible that a
              tabulator may discard fractional positions submitted to it or
              follow rounding practices, such as rounding down to the nearest
              whole share, that have the effect of excluding some fractional
              positions submitted to them from the total number of shares voted.
            </p>
            <p>
              We provide you with tools and resources to help you make informed
              investment decisions. We do not provide financial, legal, tax, or
              investment advice or any investment recommendations. You are
              responsible for your own investment decisions.
            </p>
            <p>
              We will consider whether an account with Flahmingo will be
              appropriate for you, based on certain information you provide us
              in your account application. If you have any concerns about
              whether a Flahmingo account is right for you, please contact a
              Flahmingo representative.
            </p>
            <p className="underline-text font-style-italic">
              Currency conversion
            </p>
            <p>
              Flahmingo is authorized to exchange Canadian dollars to U.S.
              dollars and from U.S. dollars to Canadian dollars for clients’
              accounts utilizing Flahmingo’s cash or through a foreign exchange
              provider (banking institution or other sophisticated institutions)
              in accordance with the clients’ instructions.
            </p>
            <p className="underline-text font-style-italic">
              Who executes your trades
            </p>
            <p>
              When you open an account with Flahmingo to purchase or hold
              securities, you enter into a contract with Flahmingo that provides
              you with the right to buy, sell, and hold securities in fractional
              quantities. You will not directly enter into a contract to
              purchase or sell securities on your account with Flahmingo’s
              executing broker, Alpaca Securities LLC (Alpaca).
            </p>
            <p>
              Alpaca receives revenue from third party liquidity providers based
              on the order flow execution received at each venue. While rebate
              rates are substantially similar amongst the liquidity providers,
              they may vary based on order characteristics (such as price of
              execution, marketability, and other characteristics). Alpaca does
              not negotiate with venues over rates as a condition of a venue
              receiving its order flow. As described in Flahmingo‘s Conflict of
              Interest Statement, there is a potential conflict inherent to a
              market maker both paying for order flow and providing price
              improvement, as the potential source of funds for each is the
              same, namely the anticipated profit the market maker seeks to earn
              from executing or facilitating the execution of client orders.
              Accordingly, from such anticipated profit, a market maker can (i)
              forgo a portion of such anticipated profit to provide price
              improvement; (ii) forgo a portion of such anticipated profit to
              pay for order flow; or (iii) retain a larger portion of
              anticipated profit and not provide (or provide less) price
              improvement or not provide (or provide less) payment for order
              flow.
            </p>
            <p className="underline-text font-style-italic">
              Payment for Order Flow and Order Routing Information
            </p>
            <p>
              Pursuant to the U.S. Securities and Exchange Commission (SEC) Rule
              606, firms are required to make publicly available a quarterly
              report with regard to their routing of non-directed orders.
              Additionally, pursuant to SEC Rule 607, firms are required to
              disclose, upon opening a new client account and on an annual basis
              thereafter: (a) their policies regarding payment for order flow,
              including a statement as to whether any payment for order flow is
              received for routing client orders and a detailed description of
              the nature of the compensation received; and (b) their policies
              for determining, in the absence of specific client instructions,
              where to route client orders that are the subject of payment for
              order flow, including a description of the extent to which orders
              can be executed at prices superior to the National Best Bid/Offer.
              For the purpose of these Rules, we have entered into an agreement
              with Alpaca to route orders on behalf of Flahmingo. Alpaca’s
              disclosures regarding order flow and order routing can be accessed
              by utilizing the following link:{" "}
              <a href="https://alpaca.markets/disclosures" target="_blank">
                https://alpaca.markets/disclosures
              </a>
              .
            </p>
            <p className="underline-text font-style-italic">
              Who holds your cash
            </p>
            <p>
              In order to purchase or sell securities with your Flahmingo
              account, you must deposit funds in your account prior to making a
              trade. Flahmingo will not hold cash on your behalf. Your
              uninvested cash balance will be held in trust by The Royal Trust
              Company (RBC Trust), a federally regulated financial institution
              by the Office of the Superintendent of Financial Institutions. In
              the course of Flahmingo’s business, Flahmingo will temporarily use
              its commercial bank accounts to handle your funds and will have
              the authority to handle cash held in trust by RBC Trust on your
              behalf. There may be a risk in permitting Flahmingo to access your
              cash, in the event that cash could be accessed improperly and
              misused. Access to your cash is only permitted to settle buy
              transactions and foreign currency transactions initiated by you.
            </p>
            <p className="underline-text font-style-italic">
              Who holds your securities
            </p>
            <p>
              Flahmingo does not directly hold any securities for you. Instead,
              all transactions in securities are settled through Depository
              Trust & Clearing Corporation (DTCC) and are held on your behalf by
              Flahmingo’s executing broker, Alpaca acting as its agent for full
              shares and as principal for fractional shares. Alpaca works with
              various third parties, including Velox Clearing LLC for securities
              transfers and custody of client securities. Alpaca through an
              omnibus arrangement with Velox Clearing LLC custodies client
              securities at DTCC. Securities and other assets are held in the
              United States.
            </p>
            <p className="underline-text font-style-italic">
              Limits on products and services offered
            </p>
            <p>
              You will be subject to a limit of <strong>$30,000</strong> per
              year that can be deposited into the account and used for trading
              (trading limit). The number of securities you can hold is limited
              to 1,000 securities. Trading limits may be amended from time to
              time and are available on our website at{" "}
              <Link to="/">www.flahmingo.com</Link>.
            </p>
            <p>
              You will not be permitted to enter into contracts that involve the
              use of leverage using the securities in your Flahmingo account.
              Securities owned by you may not be pledged, re-hypothecated, or
              otherwise used in the business of Flahmingo or its affiliates.
            </p>
          </li>
          <li>
            <h2>Fees and other compensation disclosure</h2>
            <p>
              The fees you pay are set out in the Flahmingo Fee Schedule
              provided to you at the time of account opening. This information
              is also available on our website at:{" "}
              <Link to="/">www.flahmingo.com</Link>.
            </p>
            <p>
              The cost of currency conversion fees, withholding taxes, and other
              fees and expenses charged within your account can impact the
              performance of your portfolio. When considering the fees charged
              to your account, you should note that a fee charged to your
              account will compound over time as a deduction to the overall
              value of your account. Every dollar taken out of your account to
              cover fees is one less dollar left to invest in your account to
              compound and grow over time.
            </p>
            <p className="underline-text font-style-italic">
              Trading Commission and Fees
            </p>
            <p>
              Flahmingo does not charge clients commission or other fees on
              trades. Flahmingo will be responsible for settling all fees for
              trading on exchanges.
            </p>
            <p className="underline-text font-style-italic">
              Currency Conversion Fees
            </p>
            <p>
              Flahmingo charges clients 2.5% as a foreign currency conversion
              fee. Currency conversion fees in the form of spreads are charged
              to your account when converting from Canadian dollars into U.S.
              dollars, and from U.S. dollars into Canadian dollars. The foreign
              currency conversion rate and Flahmingo‘s spread will depend on
              market fluctuations as well as the amount, date, and type of
              foreign currency transaction. Foreign currency conversions take
              place at such rates as are available to our retail clients for
              currency conversions of a similar amount, date, and type. In
              performing foreign currency transactions, Flahmingo may act as an
              agent or principal. Flahmingo may, at its discretion, reject a
              foreign currency transaction request. Flahmingo converts from
              Canadian dollars into U.S. dollars and from U.S. dollars into
              Canadian dollars on the day that it carries out the currency
              conversion, however, Flahmingo may at its discretion use a
              different day for transactions that may be agreed upon with you,
              or other transactions that Flahmingo deems necessary, at the rate
              specified in the Flahmingo Fee Schedule, as amended from time to
              time.
            </p>
            <p className="underline-text font-style-italic">Uninvested Cash</p>
            <p>
              Any cash balances held by Flahmingo will be held in trust accounts
              with RBC Royal Trust for your benefit except that you agree that
              the interest earned on those funds will be paid to Flahmingo for
              its sole benefit. You acknowledge and agree that Flahmingo is
              receiving this interest on your uninvested cash balances as
              compensation for services provided.
            </p>
            <p className="underline-text font-style-italic">Tax Withholding</p>
            <p>
              Where required by applicable legislation, you will be responsible
              for any obligations owing in any of your Flahmingo accounts,
              including withholding tax. Withholding taxes of at least 15% will
              apply to dividends (and other distributions, if applicable) made
              by U.S.-listed securities.
            </p>
            <p className="underline-text font-style-italic">
              Voluntary Corporate Actions (VCA)
            </p>
            <p>
              Flahmingo is charged a fee for processing VCAs. If you decide to
              participate in a VCA, you may be charged a fee. Please refer to
              the Flahmingo Fee Schedule.
            </p>
          </li>
          <li>
            <h4>Suitability of investments</h4>
            <p>
              As an order execution only platform, we do not provide investment
              advice and therefore, do not assess the suitability of any of your
              trades. An account with Flahmingo is intended for investors who
              are comfortable making their own investment decisions and taking
              responsibility for their investments. If you are looking for
              investment advice, you should not open a Flahmingo account.
            </p>
            <p>
              Flahmingo will not provide you with any recommendations and you
              are solely responsible for making all investment decisions in your
              order execution only accounts. Flahmingo will not be responsible
              for making a suitability determination for you and, in particular,
              Flahmingo will not consider your current financial situation,
              investment knowledge, investment objectives, time horizon, risk
              tolerance, or your account’s investment portfolio composition and
              risk level, nor other similar factors. Flahmingo will not be
              responsible for making a determination that the products and
              account types offered by us are suitable for you.
            </p>
          </li>
          <li>
            <h4>User Risk Disclosure</h4>
            <p>
              We would like to inform you of the potential risks of currency
              conversions, trading online, and the inherent risks of trading in
              an extreme market environment.
            </p>
            <p>
              When converting currencies and trading online, you should be aware
              that during periods of high internet traffic, you might experience
              delays in accessing account data due to system capacity
              limitations. Additionally, system response times may be adversely
              affected by increased market volatility conditions, quote delays,
              system performance; and other factors outside the control of
              Flahmingo, which may include your computer system and internet
              service provider. You may also experience system outages or delays
              as a result of, among other things, power failures, programming
              failures or heavy trading volume. During periods of increased
              volatility, you might suffer market losses in the price of a
              currency, and the price and share volume of a particular stock
              when system problems result in an inability to convert currencies
              and place buy or sell orders. The risk of financial loss in
              currency conversion and trading online can be substantial;
              therefore, you should consider whether such currency conversions
              and trading are suitable for you in light of your circumstances
              and financial resources.
            </p>
            <p>
              We rely on the execution services of Alpaca as our sole executing
              broker for securities and may utilize a foreign exchange provider
              for currency conversions. As with all electronic systems, they are
              vulnerable to temporary disruption or failure. In the event system
              problems prevent our automated routing systems from sending your
              order(s) to designated market centers for execution or currency
              conversion, Flahmingo will notify all clients via email that the
              application is down and the reason for the technical outage. If
              any client would like to make a trade or currency conversion, they
              would then be directed to visit the website and contact us through
              a chatbot. A client service representative will authenticate the
              client and escalate the ticket to a Registered Representative that
              will take the trade and currency conversion instructions, verify
              the information, and manually contact our executing broker or
              foreign exchange provider to place the trade or currency
              conversion until the Flahmingo application is functioning again.
              During extreme market conditions, you might experience delays in
              order executions or currency conversions because market making
              firms or our foreign exchange provider will temporarily
              discontinue normal automatic order execution standards and
              currency conversions, respectively, and switch to a manual order
              process, and/or reduce their size guarantees on individual stocks.
            </p>
            <p>
              Custody of your securities and temporary custody of your cash for
              the purposes of currency conversion by a third party may increase
              certain risks, including insolvency risk (credit risk), fraud
              risk, or proficiency risk on the part of Flahmingo. You may also
              face risk in allowing Flahmingo to have access to securities and
              cash owned by you that are held with a third party custodian in
              the event that these securities and cash could be accessed
              improperly and misused. However, access to your securities and
              cash is permitted only to settle sell transactions initiated by
              you, and to settle foreign currency transactions initiated by you,
              respectively.
            </p>
            <p>
              In the event of the insolvency or bankruptcy of our executing
              broker or its market makers, and despite insurance coverage that
              may be available by the Securities Investor Protection Corporation
              in the United States, you could face delays and other difficulties
              in repatriating your assets as well as difficulties to enforce
              your rights. When a foreign exchange provider is used for currency
              conversion, although your funds will be held by the foreign
              exchange provider in an account that is only used for the foreign
              exchange provider’s clients, and not for their operational
              purposes, in the event of their insolvency or bankruptcy, you
              could face delays and other difficulties in repatriating your
              assets as well as, difficulties to enforce your rights. As your
              securities and cash may be custodied with third parties domiciled
              outside of Canada, you may experience difficulties enforcing legal
              rights against these third parties.
            </p>
            <p>
              As is the case with omnibus arrangements, Flahmingo relies on
              Alpaca for clearing, custodial, and bookkeeping services. Your
              beneficial ownership of securities is dependent on the
              recordkeeping by Flahmingo and Alpaca. Alpaca has an omnibus
              clearing agreement to provide clearing services for full share
              transactions with Velox Clearing LLC, who is a clearing
              broker-dealer registered with the U.S. Securities and Exchange
              Commission, and a member of the U.S. Financial Industry Regulatory
              Authority, the National Securities Clearing Corporation, Deposit
              Trust Company, and the Securities Investor Protection Corporation.
              Further, Flahmingo may rely on its foreign exchange provider for
              currency conversion services. Your beneficial ownership of cash,
              while it is in the temporary custody of the foreign exchange
              provider, is dependent on the recordkeeping by Flahmingo and the
              foreign exchange provider. The foreign exchange provider may be
              regulated by regulatory authorities outside of Canada such as the
              National Futures Association and may be registered with the U.S.
              Commodity Futures Trading Commission.
            </p>
            <p>
              There is risk of loss associated with investing in securities and
              currency conversion regardless of the method used. New investors
              need to understand the principles of investing and currency
              conversion, their own risk tolerance, and their investment goals
              before trading and converting currencies. In addition, online
              clients may want to consider these other risks. High Internet
              traffic may affect online clients’ ability to access their
              accounts or transmit their orders. Online clients should be
              skeptical of stock advice, currency conversion advice, and tips
              provided in chat rooms or bulletin boards. Investors should do
              their own research before trading and converting currencies. Also,
              for some online investors, there is a temptation to “overtrade“ by
              trading too frequently or impulsively without considering their
              investment goals or risk tolerance. Overtrading can affect
              investment performance, raise trading costs, and complicate your
              tax situation. You may also experience executions and currency
              conversions at prices significantly away from the market price
              quoted or displayed at the time an order was entered, less cash or
              shares than desired, or losses.
            </p>
            <p>
              Investing in securities and currency conversions can also generate
              tax consequences for which you will be solely liable. We recommend
              that you consult with a tax adviser as to how taxes may affect the
              outcome of your contemplated transactions.
            </p>
            <p>
              The following is a summary of some of the risks of investing in
              certain kinds of investments. Please note that the following list
              of risk factors is not intended to be exhaustive, nor a
              comprehensive explanation of the risks involved, and has been
              provided as an indication of the factors that can affect the value
              of your investments.
            </p>
            <ol className="list-style-lower-alpha">
              <li>
                Equity Risks. An equity investment is subject to market
                conditions and there can be no assurance that any appreciation
                in value will occur. The value of investments and the income
                from them can fluctuate and may fall and there is no certainty
                that an investor will get back any part of its investment. The
                prices of equity securities can rise or fall significantly in a
                short period of time, due to factors including the fortunes of
                the companies that issue them or with general stock market or
                economic conditions. If the prices of equity securities that you
                hold fall in a short period of time, it could significantly
                affect the value of your investments.
              </li>
              <li>
                Credit Risk. A fixed income security, like a bond, is
                essentially a promise to pay interest and repay a specified
                amount at a later time. “Credit risk” is the probability that
                the issuer of the fixed income security will fail to honour that
                promise to pay interest. If a company or government has a high
                credit rating, the credit risk tends to be low. A lower credit
                rating means more credit risk.
              </li>
              <li>
                Debt securities risks. All debt instruments, including bonds are
                potentially exposed to risks, in particular to credit and
                interest rate risk. Debt securities may be subject to the risk
                of the issuer’s inability to meet principal and interest
                payments on the obligation and may be subject to price
                volatility due to such factors as interest rate sensitivity,
                market perception of the creditworthiness of the Issuer, general
                market liquidity and other economic factors.
              </li>
              <li>
                Short selling risks. Short selling securities involves risk
                because there is no assurance that securities will sufficiently
                decline in value during the period of the short sale to offset
                the interest paid by the investor and make a profit for the
                investor. Securities sold short may instead increase in value.
                The investor may also experience difficulties repurchasing and
                returning the borrowed securities.
              </li>
              <li>
                Interest rate risk. If interest rates fall, the values of the
                bond rises because the interest rate on the existing bond will
                be higher than the rate on newer bonds. When general interest
                rates rise, the price of existing bonds is expected to drop
                because they pay less than newer bonds.
              </li>
              <li>
                Foreign markets. Companies outside of Canada and the U.S. may be
                less regulated and have lower standards of accounting and
                financial reporting, and foreign countries may not have an
                established stock market and legal system that adequately
                protects the rights of investors may be lacking. Foreign
                investments can also be affected by social, political, or
                economic instability, and foreign governments may limit foreign
                investment, which can affect the value of investments.
              </li>
              <li>
                Foreign currency. Securities that are priced in foreign
                currencies can lose value when the Canadian dollar rises against
                the foreign currency. Foreign governments may also impose
                currency exchange restrictions, which could limit the ability to
                buy and sell certain foreign investments and could reduce the
                value of the foreign securities held by investors.
              </li>
              <li>
                Derivative investments. A derivative is a type of investment
                whose value is derived from the performance of other investments
                or from the movement of interest rates, exchange rates or market
                indices. There is no guarantee that the derivative will be
                bought or sold at the right time to make a profit or limit a
                loss, nor that the other party to the contract will meet its
                obligations. There is also no guarantee that a hedging strategy
                will always work, and hedging may not offset a drop in the value
                of a security.
              </li>
              <li>
                Exchange-traded funds (ETF) risks. An ETF may fail to accurately
                track the market segment or index that underlies its investment
                objective. The performance of an ETF may be lower than the
                performance of an actively managed fund. Some ETFs employ
                leverage, which can magnify the risk of the underlying market
                segment or index, and the market price of ETF units may trade at
                a discount to its net asset value.
              </li>
            </ol>
            <li>
              <h2>Disclosure of conflicts of interest</h2>
              <p>
                Flahmingo and its representatives must act fairly, honestly and
                in good faith with you and our other clients. We may have
                conflicts of interest in providing you with services. These
                conflicts may be actual conflicts of interest or you may
                perceive that we have a conflict of interest. For more
                information on how we handle conflicts of interest, please see
                our Conflicts of Interest Statement.
              </p>
            </li>
            <li>
              <h2>Reporting</h2>
              <p className="underline-text font-style-italic">
                Trade Confirmations
              </p>
              <p>
                When you buy or sell securities, a trade confirmation will be
                sent to you electronically, within one business day of the
                settlement date.
              </p>
              <p>
                It will contain the details of the transaction including but not
                limited to:
              </p>
              <ol className="list-style-lower-alpha">
                <li>Security name, marketplace, and dates</li>
                <li>
                  Amount paid by you for a purchase or paid to you on a sale
                </li>
                <li>
                  Amount of any commission, spread, charge or fee applied, if
                  any
                </li>
              </ol>
              <p className="underline-text font-style-italic">
                Account Statements
              </p>
              <p>
                You will receive an account statement monthly whether or not
                there has been activity in your account (excluding interest and
                dividend payments) at any time in the most recent month or in
                the two preceding months, or upon request.
              </p>
              <p>
                Each statement for the reporting period will include but is not
                limited to:
              </p>
              <ol className="list-style-lower-alpha">
                <li>Your name, address, account type, and account number</li>
                <li>
                  Book cost and market value of all holdings in the account
                </li>
                <li>
                  Activity that occurred in the account for the reporting period
                </li>
              </ol>
              <p className="underline-text font-style-italic">
                Annual Report on Charges and Other Compensation
              </p>
              <p>
                Each year, you will receive a report, for the 12 months ended
                December 31st which will set out the following:
              </p>
              <ol className="list-style-lower-alpha">
                <li>
                  The fees and charges related to the operation of your account
                </li>
                <li>
                  Any compensation received by us from an issuer of securities
                  or another dealer or adviser
                </li>
              </ol>
              <p className="underline-text font-style-italic">
                Annual Performance
              </p>
              <p>
                Each year, you will receive a report, for the 12 months ended
                December 31st which will set out the following:
              </p>
              <ol className="list-style-lower-alpha">
                <li>The change in the value of your investments</li>
                <li>
                  The amount of any deposits and withdrawals to/from your
                  account
                </li>
                <li>
                  The allocation of your assets between cash and securities
                </li>
              </ol>
            </li>
            <li>
              <h2>Benchmarks</h2>
              <p>
                An investment performance benchmark is a standard against which
                you can compare the performance of your investments. Flahmingo
                does not provide benchmarks to clients for the purpose of
                assessing the performance of a client’s investments.
              </p>
            </li>
          </li>
        </ol>
      </main>
    </Wrapper>
  </Secondary>
);
export default TermsAndConditions;